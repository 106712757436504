.button__group__footer .primary__btn {
  margin-right: 10px !important;
  border-radius: 4px !important;
  cursor: pointer;
}

.button__group__footer .primary__btn.disabled {
  color: var(--white-color) !important;
  border: 0px !important;
  opacity: 0.5;
}

.button__group__footer .secondary__btn {
  background-color: var(--white-color) !important;
  border: 1px solid var(--secondary-blue-color) !important;
  border-radius: 4px !important;
  cursor: pointer;
}

.button__group__footer .secondary__btn.disabled {
  opacity: 0.5;
  color: var(--secondary-blue-color) !important;
}

.button__group__footer .secondary__btn:hover {
  background-color: var(--white-color) !important;
  border: 1px solid var(--secondary-blue-color) !important;
  border-radius: 4px !important;
}

.export__option__dropdown {
  top:40px !important;
}

.export__option__dropdown .MuiList-root{
  padding: 0px !important;
  width: 100px !important;
 
}

.export__btn-option{
  font-size: 14px;
  font-family: var(--font-family);
}

.toaster__body{
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--primary-text-color);
}

.icon__button{
  all: unset !important
}

.highcharts-reset-zoom rect {
  fill: #000;
  opacity: 0.25;
  stroke: var(--border-color3);
}

.highcharts-reset-zoom text{
  font-family: var(--font-family-regular);
  fill: var(--primary-white-color) !important;
}


.highcharts-tooltip {
  z-index: 9998 !important;
}

.highcharts-tooltip-container{
  z-index: 99999999 !important;
}

.rms__button__badge__count  {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-blue-color);
  color: var(--primary-white-color);
  font-size: 12px !important;
  font-family: var(--font-family-regular);
  width: 19px;
  height: 19px;
  border-radius: 50%;
}

.d-flex {
  display: flex;
}
.gap-4{
  gap: 4px;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}