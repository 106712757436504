.rms__avatar__wrap {
  font-size: 12px;
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--font-color);
  max-width: 100%;
}


.circle {
    width: 30px; 
    height: 30px;
    border-radius: 50%; 
    font-size: 14px;
    line-height: 20px;
    box-shadow: 0px 0px 0px 1px #00000012 inset;
    color: white;
    flex-shrink: 0;
}

.rms__avatar__fontStyle__small {
  font-size: 10px;
  font-weight: 400;
}

.rms__avatar__fontStyle__large {
    font-size: 14px;
    font-weight: 500;
}

.rms__avatar__wrap_text {
  margin-left: 8px;
  max-width: 90%;
}

.avatar__white__border {
  border: 2px solid white
}