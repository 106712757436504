.update__log__individual__row {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    align-items: flex-start;
}

.update__log__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px 0px;
    overflow: scroll;
}  

.update__log__no__data {
    display: flex;
    justify-content: center;
}

.text-bold {
    font-weight: 700;
}

